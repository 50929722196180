import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


Footer.propTypes = {
    
};

function Footer(props) {
    return (
      <footer className="footer">
        <div className="top-footer">
          <div className="tf-container">
            <div className="row">
              <div className="col-lg-2 col-md-4">
                <div className="footer-logo">
                  <img
                    src={require("../../assets/images/logo.png")}
                    alt="images"
                  />
                </div>
              </div>
              <div className="col-lg-10 col-md-8">
                <div className="wd-social d-flex aln-center">
                  <span>Follow Us:</span>
                  <ul className="list-social d-flex aln-center">
                    <li>
                      <Link to="https://www.facebook.com/people/Saumayata-Srivastava/pfbid0hZyUk1tzpV9gKqiqwwUER44Zb8f3FhrRpRWbCyzptGLMG1b6UEkUjnUYP5GuGfVgl/">
                        <i className="icon-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/company/professional-placement-desk/">
                        <i className="icon-linkedin2"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <i className="icon-twitter"></i>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="https://www.instagram.com/professionalplacement12/profilecard/?igsh=MTNld3BhYW1hbzliNg== ">
                        <i className="icon-instagram1"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-footer">
          <div className="tf-container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-cl-1">
                  <div className="icon-infor d-flex aln-center">
                    <div className="icon">
                      <span className="icon-call-calling">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </span>
                    </div>
                    <div className="content">
                      <p>Need help? 24/7</p>
                      <h6>
                        <Link to="tel:0120 4354214">+91 - 9811226910</Link>
                        <br />
                        <Link to="tel:9811226914">+91 - 9310401956</Link>
                      </h6>
                    </div>
                  </div>
                  <p>
                    Professional Placement is Specialist Consultants in the all
                    Sector. Our approach is targeted search at all levels. Our
                    real value is going out pro-actively to the market on your
                    behalf and finding the very best candidates in the all
                    sectors.
                  </p>

                  <form
                    action="#"
                    id="subscribe-form"
                    style={{ marginTop: "20px" }}
                  >
                    <input
                      type="email"
                      placeholder="Your email address"
                      required=""
                      id="subscribe-email"
                    />
                    <button
                      className="tf-button"
                      type="submit"
                      id="subscribe-button"
                    >
                      <i className="icon-paper-plane-o"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="footer-cl-2">
                  <h6 className="ft-title">Quick Links</h6>
                  <ul className="navigation-menu-footer">
                    <li>
                      {" "}
                      <Link to="/createaccount">Sign Up</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/login">Login</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/">Home</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/job-grid">Browse Jobs</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/employers_v3">Company</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="footer-cl-3">
                  <h6 className="ft-title">Information Link</h6>
                  <ul className="navigation-menu-footer">
                    {/* <li>
                      {" "}
                      <Link to="/blog_v2">Blogs</Link>{" "}
                    </li> */}
                    <li>
                      {" "}
                      <Link to="/aboutus">About us</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/contactus">Contact us </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="footer-cl-4">
                  <h6 className="ft-title">Connect with us</h6>
                  <ul className="navigation-menu-footer">
                    <li className="ft-icon">
                      {" "}
                      <p>
                        <i className="icon-map-pin"></i>
                        <b>Address: </b>LOGIX TECHNOVA, B-3, Block B,
                        Sector 132, Noida, Uttar Pradesh 110096
                      </p>
                    </li>
                    <li className="ft-icon">
                      {" "}
                      <p>
                        <b>Email: </b>support@professionalplacementdesk.com
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-4 col-6">
                <div className="footer-cl-5">
                  <h6 className="ft-title">Download App</h6>
                  <ul className="ft-download">
                    <li>
                      {" "}
                      <Link to="#">
                        <img
                          src={require("../../assets/images/review/btn3.png")}
                          alt="images"
                        />
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="#">
                        <img
                          src={require("../../assets/images/review/btn4.png")}
                          alt="images"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="tf-container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="bt-left">
                  <div className="copyright">
                    Copyright © 2025 by Professional Placement Desk. All Rights
                    Reserved.{" "}
                  </div>
                  {/* <div className="select-language">
                    <div className="dropdown" id="language">
                      <a className="btn-selector nolink input-form">
                        <span>
                          <img
                            src={require("../../assets/images/review/flag.png")}
                            alt=""
                          />
                        </span>
                        English
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-6">
                <ul className="menu-bottom d-flex aln-center">
                  <li>
                    <Link to="term-of-use.html">Terms Of Services</Link>{" "}
                  </li>
                  <li>
                    <Link to="pricing.html">Privacy Policy</Link>{" "}
                  </li>
                  <li>
                    <Link to="contact-us.html">Cookie Policy</Link>{" "}
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;