
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from "prop-types";
import Header2 from "../components/header/Header2";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import { json, Link } from "react-router-dom";
import Gotop from "../components/gotop";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import avt from "../assets/images/user/avatar/image-01.jpg";
import { useNavigate } from "react-router-dom";
import Mobile from "../components/mobile";
import BASE_URL from '../apiconfig';

// import Header4 from "../components/header/Header4";

Checkout2.propTypes = {};

function Checkout2(props) {


  const [formData, setFormData] = useState({
    email:'',
    gender: '',
    lastc: '',
    lte: '',
    lastc1: '',
    lte1: '',
    cover: '',
    photo: "",
    resume: "",
  });

  const [jobSeekerData, setJobSeekerData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      const formDataToSend = new FormData(); 
      formDataToSend.append('email', email);

      axios
        .post(
          `${BASE_URL}/user/dashboard/getJobSeekerData2`,
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          if (response.data.status === 'success') {
            const fetchedData = response.data.data;
            // console.log(fetchedData);

            // Set job seeker data in state
            setJobSeekerData(fetchedData);
            setFormData({
              gender: fetchedData.gender || '',
              lastc: fetchedData.lastc || '',
              lte: fetchedData.lte || '',
              lastc1: fetchedData.lastc1 || '',
              lte1: fetchedData.lte1 || '',
              cover: fetchedData.cover || '',
              photo: fetchedData.photo || '',
              resume: fetchedData.resume || '',
            });
          } else {
            console.error('Failed to fetch job seeker data:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error fetching job seeker data:', error);
        });
    } else {
      console.error('Email not found in localStorage.');
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    navigate('/');
    window.location.reload();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: e.target.files[0],
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const email = localStorage.getItem("email");
  //   if (!email) {
  //     alert("Email not found. Please log in.");
  //     return;
  //   }

  //   // Prepare FormData for file uploads and other fields
  //   const formDataToSend = new FormData();
  //   formDataToSend.append('email', email);
  //   Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));
  //   axios
  //     .post(
  //       "http://localhost/professionalplacementdesk/user/dashboard/updateJobSeekerdata",
  //       formDataToSend,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         alert("Profile updated successfully!");
  //       } else {
  //         alert("Failed to update profile: " + response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error updating profile:", error);
  //       alert("Error occurred while updating profile.");
  //     });
  // };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    const email = localStorage.getItem("email");
    if (!email) {
      alert("Email not found. Please log in.");
      return;
    }
  
    // Prepare FormData with all form fields
    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));
  
    axios
      .post(
        `${BASE_URL}/user/dashboard/updateJobSeekerData`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          alert("Profile updated successfully!");
        } else {
          alert("Failed to update profile: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        alert("Error occurred while updating profile.");
      });
  };
  
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item menu-item-has-children-mobile ">
                        <Link to="/">Home</Link>
                      </li> */}

                      {/* <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/aboutus">About us</Link>
                      </li> */}
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/job-grid">Browse jobs </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/employers_v3">Companies </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/faqs">Services </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            {/* <Link to="/">Upload Resume</Link> */}
            <Mobile />
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:9811226910">9811226910</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header2 clname="actPage4" handleMobile={handleMobile} />
      {/* <Breadcrumb title="Shop" className="breadcrumb-section" /> */}
      <section className="checkout-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-4 sticky-sidebar mt-4">
              <div className="shop-content margin content-stc">
                <div className="wd-order">
                  <div className="info d-flex justify-content-center align-items-center">
                    <img
                      // src={avt}
                      src={
                        formData && formData.photo
                          ? `${BASE_URL}/uploads/` +
                            formData.photo
                          : avt
                      }
                      alt="jobtex"
                      style={{
                        width: "100px", // Make the image larger
                        height: "100px", // Ensure the height is the same
                        border: "2px solid #ccc", // Add a border
                        borderRadius: "50%", // Make it circular
                        display: "block", // Ensure it's block level
                        marginBottom: "10px", // Add some space below the image
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <h5>{jobSeekerData && jobSeekerData.name}</h5>
                  </div>
                  <div className="bg-checkout">
                    <div className="product-list">
                      <div className="product-item">
                        <div className="info">
                          {/* <img
                            src={require("../assets/images/pages/shop-5.jpg")}
                            alt="image"
                          /> */}
                          <h6>
                            <Link to="/checkout">My Profile</Link>
                          </h6>
                        </div>
                      </div>
                      <div className="product-item">
                        <div className="info">
                          {/* <img
                            src={require("../assets/images/pages/shop-2.jpg")}
                            alt="image"
                          /> */}
                          <h6>
                            <Link to="/checkout2">My details and resume</Link>
                          </h6>
                        </div>
                      </div>
                      <div className="header-customize-item button">
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#14a077",
                            color: "white",
                            textDecoration: "none",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleLogout} // Use handleLogout here
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 p-5">
              <div className="form-candidate form-checkout form-stc">
                <div className="group-title">
                  <h6>More Information</h6>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Gender</label>
                        <select
                          name="gender"
                          value={formData.gender}
                          onChange={handleInputChange}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Others</option>
                        </select>
                      </div>
                    </div>
                    <input
                      hidden
                      type="text"
                      value={localStorage.getItem("email")}
                    />
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Profile Photo</label>
                        <input
                          name="photo"
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Last or Current Company Name</label>
                        <input
                          name="lastc"
                          value={formData && formData.lastc}
                          onChange={handleInputChange}
                          type="text"
                          placeholder="current company"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>
                          Last or Current Company Experience(in years)
                        </label>
                        <input
                          name="lte"
                          type="text"
                          placeholder="current company experinece"
                          value={formData && formData.lte}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Previous Company Name</label>
                        <input
                          name="lastc1"
                          value={formData && formData.lastc1}
                          onChange={handleInputChange}
                          type="text"
                          placeholder="Previous company"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Previous Company Experience</label>
                        <input
                          name="lte1"
                          value={formData && formData.lte1}
                          onChange={handleInputChange}
                          type="text"
                          placeholder="Previous company experience"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ip out group-note">
                    <label>Cover Letter:</label>
                    <textarea
                      name="cover"
                      value={formData && formData.cover}
                      onChange={handleInputChange}
                      placeholder="Write description"
                    ></textarea>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Upload Resume</label>
                        <input
                          name="resume"
                          type="file"
                          accept=".pdf,.doc,.docx"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                        <label>Download resume</label>
                      <div className="group-input-st1">
                        {jobSeekerData?.resume && (
                          <button>
                            {" "}
                            <a
                              href={`http://localhost/professionalplacementdesk/uploads/${jobSeekerData.resume}`}
                              download
                            >
                              Download Resume
                            </a>
                          </button>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="header-customize-item button">
                    <button
                      type="submit" // Change to submit so it triggers handleSubmit
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#3498db",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Update Profile
                    </button>
                  </div>
                </form>

{jobSeekerData?.resume && (
  <div className="download-resume">
    <br /><label>Download Resume</label><br />
    <button
      style={{
        padding: "10px 20px",
        backgroundColor: "rgb(20, 160, 119)",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      <a
        href={`${BASE_URL}/user/dashboard/downloadResume/${jobSeekerData.resume}`}
        download
      >
        Download Resume
      </a>
    </button>
  </div>
)}

              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Gotop />
    </>
  );
}

export default Checkout2;
