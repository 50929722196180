import PropTypes from "prop-types";
import Header2 from "../components/header/Header2";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import Gotop from "../components/gotop";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import avt from "../assets/images/user/avatar/image-01.jpg";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Mobile from "../components/mobile";
import BASE_URL from "../apiconfig";

// import Header4 from "../components/header/Header4";

Checkout.propTypes = {};

function Checkout(props) {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    texp: "",
    texpm: "",
    qualfi: "",
    ctc: "",
    ectc: "",
    address: "",
    country: "",
    cwl: "",
    dob: "",
    nl: "",
    passport: "",
    jtime: "",
    travel: "",
    designation: "",
    key_skill: "",
    functions: "",
    roles: "",
    current_industry: "",
    past_industry: "",
  });


useEffect(() => {
  const email = localStorage.getItem("email");
  // console.log("Email from localStorage: ", email);
  if (email) {
      axios
          .post(
              `${BASE_URL}/user/dashboard/getJobSeekerData`,
              { email }
          )
          .then((response) => {
              if (response.data) {
                // console.log(response.data);
                  setFormData({
                      ...response.data,
                      email: email, // Ensure email is set in formData
                  });
              } else {
                  console.log("No data found.");
              }
          })
          .catch((error) => console.error("Error fetching data: ", error));
  } else {
      console.error("Email not found in localStorage.");
  }
}, []);


const handleSubmit = (e) => {
  e.preventDefault();
  if (!formData.email) {
    alert("Email is required.");
    return;
}
  
  // Get only the fields that are being updated
  const updatedData = {
      email: formData.email, // Always include the email or unique identifier
      ...(formData.mobile && { mobile: formData.mobile }),
      ...(formData.name && { name: formData.name }),
      ...(formData.qualfi && { qualfi: formData.qualfi }),
      ...(formData.texp && { texp: formData.texp }),
      ...(formData.texpm && { texpm: formData.texpm }),
      ...(formData.ctc && { ctc: formData.ctc }),
      ...(formData.ectc && { ectc: formData.ectc }),
      ...(formData.address && { address: formData.address }),
      ...(formData.country && { country: formData.country }),
      ...(formData.cwl && { cwl: formData.cwl }),
      ...(formData.dob && { dob: formData.dob }),
      ...(formData.nl && { nl: formData.nl }),
      ...(formData.passport && { passport: formData.passport }),
      ...(formData.jtime && { jtime: formData.jtime }),
      ...(formData.travel && { travel: formData.travel }),
      ...(formData.designation && { designation: formData.designation }),
      ...(formData.key_skill && { key_skill: formData.key_skill }),
      ...(formData.functions && { functions: formData.functions }),
      ...(formData.roles && { roles: formData.roles }),
      ...(formData.current_industry && { current_industry: formData.current_industry }),
      ...(formData.past_industry && { past_industry: formData.past_industry }),
      // Add other fields that you want to update
  };
  // console.log("Data being sent to server:", updatedData);

  axios
    .post(
      `${BASE_URL}/user/dashboard/updateJobSeeker`,
      updatedData, 
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // console.log("Response from server: ", response.data);
      // Handle success and update UI
    })
    .catch((error) => {
      console.error("Error updating profile: ", error);
      alert("Error occurred while updating profile.");
    });
    
};

const navigate = useNavigate();

const handleLogout = () => {
  localStorage.removeItem("isLoggedIn");
  navigate("/"); // Redirect to the home page
  window.location.reload(); // Refresh the page to update the login/signup buttons
};


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item menu-item-has-children-mobile ">
                        <Link to="/">Home</Link>
                      </li> */}

                      {/* <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/aboutus">About us</Link>
                      </li> */}
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/job-grid">Browse jobs </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/employers_v3">Companies </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/faqs">Services </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            {/* <Link to="/">Upload Resume</Link> */}
            <Mobile />
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:9811226910">9811226910</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header2 clname="actPage4" handleMobile={handleMobile} />
      {/* <Breadcrumb title="Shop" className="breadcrumb-section" /> */}
      <section className="checkout-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-4 sticky-sidebar mt-4">
              <div className="shop-content margin content-stc">
                <div className="wd-order">
                  <div className="info d-flex justify-content-center align-items-center">
                    <img
                      // src={avt}
                      src={
                        formData.photo
                          ? `${BASE_URL}/uploads/${formData.photo}`
                          : avt
                      }
                      alt="jobtex"
                      style={{
                        width: "100px", // Make the image larger
                        height: "100px", // Ensure the height is the same
                        border: "2px solid #ccc", // Add a border
                        borderRadius: "50%", // Make it circular
                        display: "block", // Ensure it's block level
                        marginBottom: "10px", // Add some space below the image
                      }}
                    />
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                    <h5>{formData.name}</h5>
                  </div>
                  <div className="bg-checkout">
                    <div className="product-list">
                      <div className="product-item">
                        <div className="info">
                          {/* <img
                            src={require("../assets/images/pages/shop-5.jpg")}
                            alt="image"
                          /> */}
                          <h6>
                            <Link to="/checkout">My Profile</Link>
                          </h6>
                        </div>
                      </div>
                      <div className="product-item">
                        <div className="info">
                          {/* <img
                            src={require("../assets/images/pages/shop-2.jpg")}
                            alt="image"
                          /> */}
                          <h6>
                            <Link to="/checkout2">My details and resume</Link>
                          </h6>
                        </div>
                      </div>
                      <div className="header-customize-item button">
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#14a077",
                            color: "white",
                            textDecoration: "none",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleLogout} // Use handleLogout here
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 p-5">
              <div className="form-candidate form-checkout form-stc">
                <div className="group-title">
                  <h6>Personal Details</h6>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Your Full Name</label>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Mobile Number</label>
                        <input
                          type="text"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Email Address</label>

                        <input
                          type="email"
                          name="email"
                          value={formData.email} // Ensure this value is being filled from state
                          readOnly // Keep it readonly, but don't disable it
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Total Experience in Years</label>
                        <input
                          type="number"
                          value={formData.texp}
                          name="texp"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Total Experience in Months</label>
                        <input
                          type="number"
                          value={formData.texpm}
                          name="texpm"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Highest Qualification:</label>
                        <select
                          name="qualfi"
                          value={formData.qualfi}
                          onChange={handleInputChange}
                        >
                          <option value="10th">10th</option>
                          <option value="12th">12th</option>
                          <option value="Graduation/Diploma">
                            Graduation/Diploma
                          </option>
                          <option value="Masters/Post-Graduation">
                            Masters/Post-Graduation
                          </option>
                          <option value="Doctorate/PhD">Doctorate/PhD</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Current Salary per year (in rupees) </label>
                        <input
                          value={formData.ctc}
                          onChange={handleInputChange}
                          name="ctc"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Expected Salary per year (in rupees) </label>
                        <input
                          value={formData.ectc}
                          onChange={handleInputChange}
                          name="ectc"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Full Address </label>
                        <input
                          value={formData.address}
                          onChange={handleInputChange}
                          name="address"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Key Skills </label>
                        <input
                          value={formData.key_skill}
                          onChange={handleInputChange}
                          name="key_skill"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Designation </label>
                        <input
                          value={formData.designation}
                          onChange={handleInputChange}
                          name="designation"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Roles </label>
                        <input
                          value={formData.roles}
                          onChange={handleInputChange}
                          name="roles"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Functions </label>
                        <input
                          value={formData.functions}
                          onChange={handleInputChange}
                          name="functions"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Current Industry </label>
                        <input
                          value={formData.current_industry}
                          onChange={handleInputChange}
                          name="current_industry"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Past Industry </label>
                        <input
                          value={formData.past_industry}
                          onChange={handleInputChange}
                          name="past_industry"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Country </label>
                        <input
                          value={formData.country}
                          onChange={handleInputChange}
                          name="country"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Current Work Location </label>
                        <input
                          value={formData.cwl}
                          onChange={handleInputChange}
                          name="cwl"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Date of Birth </label>
                        <input
                          value={formData.dob}
                          onChange={handleInputChange}
                          name="dob"
                          type="date"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Nationality</label>
                        <input
                          value={formData.nl}
                          onChange={handleInputChange}
                          name="nl"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Passport Number</label>
                        <input
                          value={formData.passport}
                          onChange={handleInputChange}
                          name="passport"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Joining Time:</label>
                        <select
                          name="jtime"
                          value={formData.jtime}
                          onChange={handleInputChange}
                        >
                          <option value="immdiately">immdiately</option>
                          <option value="with in 15 days">
                            with in 15 days
                          </option>
                          <option value="with in 30 days">
                            with in 30 days
                          </option>
                          <option value="with in 45 days">
                            with in 45 days
                          </option>
                          <option value="with in 60 days">
                            with in 60 days
                          </option>
                          <option value="with in 90 days">
                            with in 90 days
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group-input-st1">
                        <label>Are you willing to travel</label>
                        <select
                          name="travel"
                          value={formData.travel}
                          onChange={handleInputChange}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="header-customize-item button">
                    <button
                      type="submit"
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#3498db",
                        color: "white",
                        textDecoration: "none",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // localStorage.removeItem("isLoggedIn");
                        window.location.reload(); // refresh the page to show login/signup buttons again
                      }}
                    >
                      Update Profile
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Gotop />
    </>
  );
}

export default Checkout;