// import React, { useEffect , useState } from "react";
// import PropTypes from "prop-types";
// import SelectLocation from "../dropdown";
// import axios from 'axios';
// import BASE_URL from "../../apiconfig";


// FormSearch.propTypes = {};

// function FormSearch(props) {


//   const [categories, setCategories] = useState([]);
//   const [profiles, setProfiles] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedProfile, setSelectedProfile] = useState(''); // Track selected profile

//   useEffect(() => {
//     axios.get(`${BASE_URL}/user/dashboard/getJobCategories`)
//       .then(response => setCategories(response.data))
//       .catch(error => console.error('Error fetching categories:', error));
//   }, []);

//   useEffect(() => {
//     if (selectedCategory) {
//       axios.get(`${BASE_URL}/user/dashboard/getProfilesByCategory/${selectedCategory}`)
//         .then(response => {
//           console.log('Profiles data:', response.data); // Log the response to check data
//           setProfiles(response.data);
//         })
//         .catch(error => console.error('Error fetching profiles:', error));
//     }
//   }, [selectedCategory]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const url = `/job-grid?profile_id=${selectedProfile}`;
//     console.log('Redirecting to:', url); // Check the generated URL
//     window.location.href = url;
//   };
  
//   return (
//     <section className="form-sticky stc1">
//       <div className="tf-container">
//         <div className="job-search-form inner-form-map st1">
//           {/* code starts here */}
//           <form onSubmit={handleSubmit}>
//                   <div className="row-group-search home1 st">
//                     <div className="form-group-1">
//                       <select
//                         className="input-filter-search"
//                         value={selectedCategory}
//                         onChange={(e) => setSelectedCategory(e.target.value)}
//                       >
//                         <option value="">Select Job Category</option>
//                         {categories.map((category) => (
//                           <option key={category.id} value={category.id}>
//                             {category.catname}
//                           </option>
//                         ))}
//                       </select>
//                     </div>

//                     <div className="form-group-1">
//                       <select
//                         className="input-filter-search"
//                         value={selectedProfile}
//                         onChange={(e) => setSelectedProfile(e.target.value)}
//                       >
//                         <option value="">Select Job Profile</option>
//                         {profiles.map((profile) => (
//                           <option key={profile.id} value={profile.id}>
//                             {profile.pname} {/* Display profile name */}
//                           </option>
//                         ))}
//                       </select>
//                     </div>

//                     <div className="form-group-4">
//                       <button type="submit" className="btn btn-find">
//                         Find Jobs
//                       </button>
//                     </div>
//                   </div>
//                 </form>

          
//         </div>
//       </div>
//     </section>
//   );
// }

// export default FormSearch;














import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import BASE_URL from "../../apiconfig";

FormSearch.propTypes = {};

function FormSearch(props) {
  const [domains, setDomains] = useState([]);
  const [categories, setCategories] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProfile, setSelectedProfile] = useState('');

  // Fetch domains on component mount
  useEffect(() => {
    axios.get(`${BASE_URL}/user/dashboard/getJobDomains`)
      .then(response => setDomains(response.data))
      .catch(error => console.error('Error fetching domains:', error));
  }, []);

  // Fetch categories when domain is selected
  useEffect(() => {
    if (selectedDomain) {
      axios.get(`${BASE_URL}/user/dashboard/getCategoriesByDomain/${selectedDomain}`)
        .then(response => {
          setCategories(response.data);
          // Reset dependent fields
          setSelectedCategory('');
          setSelectedProfile('');
          setProfiles([]);
        })
        .catch(error => console.error('Error fetching categories:', error));
    } else {
      setCategories([]);
      setSelectedCategory('');
      setSelectedProfile('');
      setProfiles([]);
    }
  }, [selectedDomain]);

  // Fetch profiles when category is selected
  useEffect(() => {
    if (selectedCategory) {
      axios.get(`${BASE_URL}/user/dashboard/getProfilesByCategory/${selectedCategory}`)
        .then(response => {
          setProfiles(response.data);
          setSelectedProfile('');
        })
        .catch(error => console.error('Error fetching profiles:', error));
    } else {
      setProfiles([]);
      setSelectedProfile('');
    }
  }, [selectedCategory]);

  const handleDomainChange = (e) => {
    setSelectedDomain(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleProfileChange = (e) => {
    setSelectedProfile(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedProfile) {
      const url = `/job-grid?profile_id=${selectedProfile}`;
      window.location.href = url;
    }
  };

  return (
    <section className="form-sticky stc1">
      <div className="tf-container">
        <div className="job-search-form inner-form-map st1">
          <form onSubmit={handleSubmit}>
            <div className="row-group-search home1 st">
              <div className="form-group-1">
                <select
                  className="input-filter-search"
                  value={selectedDomain}
                  onChange={handleDomainChange}
                >
                  <option value="">Select Industry</option>
                  {domains.map((domain) => (
                    <option key={domain.id} value={domain.id}>
                      {domain.domname}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group-1">
                <select
                  className="input-filter-search"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  disabled={!selectedDomain}
                >
                  <option value="">Select Job Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.catname}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group-1">
                <select
                  className="input-filter-search"
                  value={selectedProfile}
                  onChange={handleProfileChange}
                  disabled={!selectedCategory}
                >
                  <option value="">Select Job Profile</option>
                  {profiles.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      {profile.pname}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group-4">
                <button 
                  type="submit" 
                  className="btn btn-find"
                  disabled={!selectedProfile}
                >
                  Find Jobs
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default FormSearch;