// import React from "react";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import SortBuy from "../dropdown/SortBuy";

// JobSec2.propTypes = {};

// function JobSec2(props) {
//   const { data } = props;
//   const { className } = props;
//   const { handlePopup } = props;
//   return (
//     <section className={`inner-jobs-section ${className}`}>
//       <div className="tf-container">
//         <div className="row">
//           <Tabs className="col-lg-12 tf-tab">
//             <div className="wd-meta-select-job">
//               <div className="wd-findjob-filer">
//                 <div className="group-select-display">
//                   <Link className="button-filter st2" onClick={handlePopup}>
//                     <i className="icon-filter"></i> Filters
//                   </Link>
//                   <TabList className="inner menu-tab">
//                     <Tab className="btn-display">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="17"
//                         height="16"
//                         viewBox="0 0 17 16"
//                         fill="none"
//                       >
//                         <path
//                           d="M4.5 0H0.500478C0.5 0.380952 0.5 0.596931 0.5 1.33333V14.6667C0.5 15.4031 0.500478 16 0.500478 16H4.5C4.5 16 4.5 15.4031 4.5 14.6667V1.33333C4.5 0.596931 4.5 0.380952 4.5 0Z"
//                           fill="white"
//                         />
//                         <path
//                           d="M10.5 0H6.50048C6.5 0.380952 6.5 0.596931 6.5 1.33333V14.6667C6.5 15.4031 6.50048 16 6.50048 16H10.5C10.5 16 10.5 15.4031 10.5 14.6667V1.33333C10.5 0.596931 10.5 0.380952 10.5 0Z"
//                           fill="white"
//                         />
//                         <path
//                           d="M16.5 0H12.5005C12.5 0.380952 12.5 0.596931 12.5 1.33333V14.6667C12.5 15.4031 12.5005 16 12.5005 16H16.5C16.5 16 16.5 15.4031 16.5 14.6667V1.33333C16.5 0.596931 16.5 0.380952 16.5 0Z"
//                           fill="white"
//                         />
//                       </svg>
//                     </Tab>
//                     <Tab className="btn-display">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="17"
//                         height="16"
//                         viewBox="0 0 17 16"
//                         fill="none"
//                       >
//                         <path
//                           d="M0.5 12.001L0.5 16.0005C0.880952 16.001 1.09693 16.001 1.83333 16.001L15.1667 16.001C15.9031 16.001 16.5 16.0005 16.5 16.0005L16.5 12.001C16.5 12.001 15.9031 12.001 15.1667 12.001L1.83333 12.001C1.09693 12.001 0.880952 12.001 0.5 12.001Z"
//                           fill="#A0A0A0"
//                         />
//                         <path
//                           d="M0.5 6.00098L0.5 10.0005C0.880952 10.001 1.09693 10.001 1.83333 10.001L15.1667 10.001C15.9031 10.001 16.5 10.0005 16.5 10.0005L16.5 6.00098C16.5 6.00098 15.9031 6.00098 15.1667 6.00098L1.83333 6.00098C1.09693 6.00098 0.880952 6.00098 0.5 6.00098Z"
//                           fill="#A0A0A0"
//                         />
//                         <path
//                           d="M0.5 0.000976562L0.5 4.0005C0.880952 4.00098 1.09693 4.00098 1.83333 4.00098L15.1667 4.00098C15.9031 4.00098 16.5 4.0005 16.5 4.0005L16.5 0.000975863C16.5 0.000975863 15.9031 0.000975889 15.1667 0.000975921L1.83333 0.000976504C1.09693 0.000976536 0.880952 0.000976546 0.5 0.000976562Z"
//                           fill="#A0A0A0"
//                         />
//                       </svg>
//                     </Tab>
//                   </TabList>
//                   <p className="nofi-job">
//                     <span>1249</span> jobs recommended for you
//                   </p>
//                 </div>
//                 <SortBuy />
//               </div>
//             </div>
//             <div className="content-tab">
//               <TabPanel className="inner">
//                 <div className="group-col-2">
//                   {data.slice(0, 8).map((idx) => (
//                     <div key={idx.id} className="features-job cl2">
//                       <div className="job-archive-header">
//                         <div className="inner-box">
//                           <div className="logo-company">
//                             <img src={idx.img} alt="jobtex" />
//                           </div>
//                           <div className="box-content">
//                             <h4>
//                               <Link to="/Jobsingle_v1">{idx.cate}</Link>
//                             </h4>
//                             <h3>
//                               <Link to="/Jobsingle_v1"> {idx.title} </Link>
//                               <span className="icon-bolt"></span>
//                             </h3>
//                             <ul>
//                               <li>
//                                 <span className="icon-map-pin"></span>
//                                 {idx.map}
//                               </li>
//                               <li>
//                                 <span className="icon-calendar"></span>
//                                 {idx.time}
//                               </li>
//                             </ul>
//                             <span className="icon-heart"></span>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="job-archive-footer">
//                         <div className="job-footer-left">
//                           <ul className="job-tag">
//                             <li>
//                               <Link to="#">{idx.jobs1}</Link>
//                             </li>
//                             <li>
//                               <Link to="#">{idx.jobs2}</Link>
//                             </li>
//                           </ul>
//                           <div className="star">
//                             <span className="icon-star-full"></span>
//                             <span className="icon-star-full"></span>
//                             <span className="icon-star-full"></span>
//                             <span className="icon-star-full"></span>
//                             <span className="icon-star-full"></span>
//                           </div>
//                         </div>
//                         <div className="job-footer-right">
//                           <div className="price">
//                             <span className="icon-dolar1"></span>
//                             <p>
//                               {idx.price}
//                               <span className="year">/year</span>
//                             </p>
//                           </div>
//                           <p className="days">{idx.apply}</p>
//                         </div>
//                       </div>
//                       <Link
//                         to="/Jobsingle_v1"
//                         className="jobtex-link-item"
//                         tabIndex="0"
//                       ></Link>
//                     </div>
//                   ))}
//                 </div>

//                 <ul className="pagination-job padding">
//                   <li>
//                     <Link to="#">
//                       <i className="icon-keyboard_arrow_left"></i>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="#">1</Link>
//                   </li>
//                   <li className="current">
//                     <Link to="#">2</Link>
//                   </li>
//                   <li>
//                     <Link to="#">3</Link>
//                   </li>
//                   <li>
//                     <Link to="#">
//                       <i className="icon-keyboard_arrow_right"></i>
//                     </Link>
//                   </li>
//                 </ul>
//               </TabPanel>
             
//             </div>
//           </Tabs>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default JobSec2;




import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import img1 from "../../assets/images/partners/thum-1.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SortBuy from "../dropdown/SortBuy";
import BASE_URL from "../../apiconfig";

function JobSec2({ isShow, handlePopup }) {

  // code start here

  // const [data, setData] = useState([]);
  // const { companyId } = useParams();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const profileId = searchParams.get('profile_id'); // Get the profile ID from URL

  // useEffect(() => {
  //   let url = 'http://localhost/professionalplacementdesk/user/dashboard/getjobs';
  //   if (companyId) {
  //     url += `?company_id=${companyId}`;
  //   }
  //   if (profileId) {
  //     url += `${companyId ? '&' : '?'}profile_id=${profileId}`; // Append profile_id query parameter
  //   }

  //   console.log('Fetching jobs from:', url); // Check the constructed URL

  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((jobData) => {
  //       setData(jobData);
  //       console.log('Job data:', jobData); // Log the response to verify if jobs are fetched
  //     })
  //     .catch((error) => console.error('Error fetching job data:', error));
  // }, [companyId, profileId]);

  
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  // useEffect(() => {
  //   const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
  //   setIsLoggedIn(loggedInStatus);
  // }, []);
  
  // const buttonStyle = {
  //   padding: '10px 20px',
  //   backgroundColor: isLoggedIn ? '#4CAF50' : '#f44336', // Green if logged in, red if not
  //   color: 'white',
  //   border: 'none',
  //   borderRadius: '5px',
  //   cursor: 'pointer',
  //   marginTop: '20px', // Add margin-top
  // };
  // code ends here

  const [data, setData] = useState([]);
  const { companyId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const profileId = searchParams.get('profile_id'); // Get the profile ID from URL
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    let url = `${BASE_URL}/user/dashboard/getjobs`;
    if (companyId) {
      url += `?company_id=${companyId}`;
    }
    if (profileId) {
      url += `${companyId ? '&' : '?'}profile_id=${profileId}`; // Append profile_id query parameter
    }

    // console.log('Fetching jobs from:', url); // Check the constructed URL

    fetch(url)
      .then((response) => response.json())
      .then((jobData) => {
        setData(jobData);
        // console.log('Job data:', jobData); // Log the response to verify if jobs are fetched
      })
      .catch((error) => console.error('Error fetching job data:', error));
  }, [companyId, profileId]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: isLoggedIn ? '#4CAF50' : '#f44336', // Green if logged in, red if not
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px', // Add margin-top
  };

  const handleButtonClick = (jobId) => {
    if (!isLoggedIn) {
      navigate('/login'); // Navigate to /login if not logged in
    } else {
      // Navigate to the job detail page
      navigate(`/Jobsingle_v1/${jobId}`);
    }
  };

  return (
    <section className="inner-jobs-section">
      <div className="tf-container">
        <div className="row">
          <Tabs className="col-lg-12 tf-tab">
            <div className="wd-meta-select-job"></div>
            <div className="content-tab">
              <TabPanel className="inner">
                <div className="group-col-2">
                  {data.length > 0 ? (
                    data.map((job, idx) => (
                      <div key={idx} className="features-job cl2">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              {/* <img
                                src={
                                  job.image
                                    ? `http://localhost/professionalplacementdesk/uploads/${job.image}`
                                    : img1
                                }
                                alt={job.company_name}
                              /> */}
                              <Link to={`/Jobsingle_v1/${job.id}`}>
                                <img
                                  src={
                                    job.image &&
                                    job.image !== "null" &&
                                    job.image !== ""
                                      ? `${BASE_URL}/uploads/${job.image}`
                                      : img1
                                  }
                                  alt={job.company_name}
                                />
                              </Link>
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to={`/Jobsingle_v1/${job.id}`}>
                                  {job.company_name}
                                </Link>
                              </h4>
                              <h3>
                                <Link to={`/Jobsingle_v1/${job.id}`}>
                                  {job.title}
                                </Link>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  {job.location}
                                </li>
                                <li>
                                  <span className="icon-rupee"></span>
                                  {job.salarymin} - {job.salarymax} / year
                                </li>
                              </ul>
                              {/* <div className="group-btn">
                                <button style={buttonStyle}>
                                  {isLoggedIn ? "Apply" : "Login to Apply"}
                                </button>
                              </div> */}
                              <div className="group-btn">
                                <button
                                  style={buttonStyle}
                                  onClick={() => handleButtonClick(job.id)}
                                >
                                  {isLoggedIn ? "Apply" : "Login to Apply"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No jobs found for this profile.</div> // Handle no data case
                  )}
                </div>

                <ul className="pagination-job padding">
                  {/* Pagination logic */}
                </ul>
              </TabPanel>

              {/* code end here */}
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default JobSec2;