import React, { useEffect, useState } from 'react';
import avt from "../../assets/images/user/avatar/image-01.jpg";
import axios from 'axios';
import { Link, NavLink } from "react-router-dom";
import BASE_URL from '../../apiconfig';

import { useNavigate } from "react-router-dom";


function Index() {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const navigate = useNavigate(); // Call useNavigate at the top of the component
    const handleLogout = () => {
      localStorage.removeItem("isLoggedIn");
      navigate("/"); // Redirect to the home page
      window.location.reload(); // Refresh the page to update the login/signup buttons
    }; 

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        texp: '',
        qualfi: '',
        ctc: '',
        ectc: '',
        address: '',
        country: '',
        cwl: '',
        dob: '',
        nl: '',
        passport: '',
        jtime: '',
        travel: ''
      });

    useEffect(() => {
        const email = localStorage.getItem('email');
        // console.log("Email from localStorage heheh: ", email);
        if (email) {
           axios.post(`${BASE_URL}/user/dashboard/getJobSeekerData`, { email })
              .then(response => {
                 if (response.data) {
                    setFormData(response.data);
                 } else {
                    console.log("No data found.");
                 }
              })
              .catch(error => console.error("Error fetching data: ", error));
        } else {
           console.error("Email not found in localStorage.");
        }
        
     }, []);
  return (
    <div >
      {/* <Link to="/">Upload Resume</Link> */}
      {isLoggedIn ? (
        <>
          <a href="/checkout" className="header-customize-item account">
            <img
              src={
                formData.photo
                  ? `${BASE_URL}/uploads/${formData.photo}`
                  : avt
              }
              alt="jobtex"
            />
            <div className="name">Candidates</div>
          </a>
          <div className="header-customize-item button">
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#14a077",
                color: "white",
                textDecoration: "none",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleLogout} // Use handleLogout here
            >
              Logout
            </button>
          </div>
        </>
      ) : (
        <>
          <div
            className="header-customize-item button"
            style={{ marginRight: "15px" }}
          >
            <Link to="/createaccount">Sign Up</Link>
          </div>
          <div 
            className="header-customize-item button"
            style={{ marginRight: "15px" }} 
          >
            <Link to="/login">Login</Link>
          </div>
        </>
      )}
    </div>
  );
}

export default Index;