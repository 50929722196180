import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import logo from "../../assets/images/logo.png";
import avt from "../../assets/images/user/avatar/image-01.jpg";
import { Link, NavLink } from "react-router-dom";
import BASE_URL from "../../apiconfig";

Header2.propTypes = {};

function Header2({ clname = "", handleMobile }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  
  const [scroll, setScroll] = useState(0);
  
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    texp: '',
    qualfi: '',
    ctc: '',
    ectc: '',
    address: '',
    country: '',
    cwl: '',
    dob: '',
    nl: '',
    passport: '',
    jtime: '',
    travel: ''
  });
  
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, []);
  
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const navigate = useNavigate(); // Call useNavigate at the top of the component
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/"); // Redirect to the home page
    window.location.reload(); // Refresh the page to update the login/signup buttons
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const email = localStorage.getItem('email');
    // console.log("Email from localStorage heheh: ", email);
    if (email) {
       axios.post(`${BASE_URL}/user/dashboard/getJobSeekerData`, { email })
          .then(response => {
             if (response.data) {
                setFormData(response.data);
             } else {
                console.log("No data found.");
             }
          })
          .catch(error => console.error("Error fetching data: ", error));
    } else {
       console.error("Email not found in localStorage.");
    }
    
 }, []);
 

  return (
    <header
      id="header"
      className={`header header-default ${scroll ? "is-fixed is-small" : ""}`}
    >
      <div className="tf-container ct2">
        <div className="row">
          <div className="col-md-12">
            <div className="sticky-area-wrap">
              <div className="header-ct-left">
                <div id="logo" className="logo">
                  <Link to="/">
                    <img
                      className="site-logo"
                      id="trans-logo"
                      src={logo}
                      alt="Image"
                    />
                  </Link>
                </div>
                
              </div>
              <div className="header-ct-center">
                <div className="nav-wrap">
                  <nav id="main-nav" className="main-nav">
                    <ul id="menu-primary-menu" className={`menu ${clname}`}>
                      {/* <li className="menu-item sub1">
                        <Link to="/">Home </Link>
                      </li> */}
                      {/* <li className="menu-item sub2">
                        <Link to="/aboutus">About Us</Link>
                      </li> */}

                      <li className="menu-item sub3">
                        <Link to="/job-grid">Browse Jobs</Link>
                      </li>
                      <li className="menu-item sub4">
                        <Link to="/employers_v3">Companies</Link>
                      </li>
                      <li className="menu-item sub5">
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li className="menu-item sub6">
                        <Link to="/faqs">Services</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="header-ct-right">
               
                {isLoggedIn ? (
                  <>
                    <a
                      href="/checkout"
                      className="header-customize-item account"
                    >
                      <img 
                      src={formData.photo ? `${BASE_URL}/uploads/${formData.photo}` : avt}

                       alt="jobtex" />
                      <div className="name">Candidates</div>
                    </a>
                    <div className="header-customize-item button">
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#14a077",
                          color: "white",
                          textDecoration: "none",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={handleLogout} // Use handleLogout here
                      >
                        Logout
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="header-customize-item button"
                      style={{ marginRight: "15px" }}
                    >
                      <Link to="/createaccount">Sign Up</Link>
                    </div>
                    <div className="header-customize-item button">
                      <Link to="/login">Login</Link>
                    </div>
                  </>
                )}
              </div>
              <div className="nav-filter" onClick={handleMobile}>
                <div className="nav-mobile">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header2;
